import Link from 'next/link'
import { useEffect, useState } from 'react'

export default function CallToActionTypeForm(props: { text?: string }) {
    const { text } = props
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        setIsReady(true)
    }, [])

    if (!isReady) {
        return null
    }

    return (
        <>
            <Link
                // href="https://flyeducacional.typeform.com/to/EWip1tyh"
                href={`/form`}
                className={`my-12 !h-16 rounded-3xl btn md:btn-lg bg-lime-500 border-0 text-xl lg:!text-3xl !font-semibold w-fit flex mx-auto relative animate-bounce`}
            >
                {text}
            </Link>
        </>
    )
}
